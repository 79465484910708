import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./search.events";
export class Search extends Component implements ComponentType {
    name = 'Search';
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '.aui-search-dropdown';
        this.element = $(this.elementRef);
        Events.keyup.call(this);
        Events.select.call(this);
        Events.blur.call(this);
        Events.click.call(this);
    }
    findingSearchword(keyWord) {
        let items = this.element.find(".aui-search-li");
        let results = items.length;
        for (const item in items) {
            if (Object.prototype.hasOwnProperty.call(items, item)) {
                const element = items[item];
                if (element.textContent) {
                    if (element.textContent.toUpperCase().indexOf(keyWord.toUpperCase()) > -1) {
                        let txt = String(element.textContent).replace(new RegExp(keyWord, "gi"), "<b>$&</b>");
                        $(element).html(txt);
                        $(element).parent().css('display', '');
                    } else {
                        $(element).parent().css('display', 'none');
                        results--;
                    }
                }
            }
        }
        if (!results && keyWord) {
            $('.aui-no-result-li').css('display', 'block')
        } else {
            $('.aui-no-result-li').css('display', 'none')
        }
    }
    showULcontainer() {
        $('.aui-input-div').toggleClass('show');
        $('.aui-search-ui').toggleClass('aui-show-ul');
        $('.aui-search-ui').removeClass('aui-no-result-hide');
        $('.aui-search-dropdown input.form-control').val("");
        $('.aui-search-dropdown input.form-control').focus();
        this.findingSearchword('');
    }
    onHidesearchdropDown() {
        $('.aui-input-div').removeClass('show');
        $('.aui-search-ui').removeClass('aui-show-ul');
    }
    onselectOption(e) {
        this.element.find("input").val($(e.target).closest('.aui-search-li').text());
    }
    setfocustoULcontainer(e){
        if($('.aui-search-ui').is(':visible')) {
            $('.aui-search-ui li:first-child').focus();
        }
    }
}
