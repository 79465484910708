import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./tabs.events"
export class Tab extends Component implements ComponentType {
    name = 'Tabs';

    onDomReady() {
        this.init();
    }
    onWindowResize() {
        this.onresizeTabNavigation();
    }
    init() {
        this.elementRef = '.aui-vertical-tabs';
        this.element = $(this.elementRef);
        this.onresizeTabNavigation();
        Events.onKeyPress.call(this); 
    }

    onresizeTabNavigation(){
        $('.header-title').attr('aria-hidden','true');
        if ($(window).width() < 767) {
            $('.header-title').attr('aria-hidden','false');
            $('#content').removeAttr('tabindex');
            $('div.tab-pane').each(function() {
                var $TabPane = $(this);
                $TabPane.removeAttr('tabindex');
                $TabPane.find('.card-body-content').attr('tabindex','0');
                $(".collapsed", $TabPane).click(function(e) {
                    $(this).parent().parent().siblings('.tab-pane').children('.card-header').children('.header-title').addClass('collapsed');
                    $(this).parent().parent().siblings('.tab-pane').children('.collapse').removeClass('show');
                    $(this).parent().parent().siblings('.tab-pane').children('.card-header').children('.collapsed').attr('aria-expanded','false');
                });
            });
        }else {
            $('.tab-content').removeAttr('role');
            $('div.tab-pane').each(function() {
                var $TabPane = $(this);
                $TabPane.find('.card-header').removeAttr('role'); 
            });
        }
    }

    // triggering click event when keypressed
    triggerKeyPressEvent(e){
        if ($(window).width() > 767) {
            if (e.keyCode === 39 || e.keyCode === 40) {
                if(e.currentTarget.nextElementSibling){
                    e.currentTarget.setAttribute('tabindex','-1')
                    e.currentTarget.setAttribute('aria-selected','false')
                    e.currentTarget.nextElementSibling.click();
                    e.currentTarget.nextElementSibling.focus();
                    e.currentTarget.nextElementSibling.setAttribute('tabindex','0');
                    e.currentTarget.nextElementSibling.setAttribute('aria-selected','true');
                }
            }else if(e.keyCode === 37 || e.keyCode === 38){
                if(e.currentTarget.previousElementSibling){
                    e.currentTarget.setAttribute('tabindex','-1')
                    e.currentTarget.setAttribute('aria-selected','false')
                    e.currentTarget.previousElementSibling.click();
                    e.currentTarget.previousElementSibling.focus();
                    e.currentTarget.previousElementSibling.setAttribute('tabindex','0');
                    e.currentTarget.previousElementSibling.setAttribute('aria-selected','true');
                }
            }
        }
    }
}