import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./popover.events";
export class Popover extends Component implements ComponentType {
    name: string = 'Popover';
    onDomReady() {
        Events.init.call(this);
    }
    init() {
        this.elementRef = '[data-toggle="popover"]';
        this.element = $(this.elementRef);
        this.element.popover();
    }
}
