import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./filter.events"
export class Filter extends Component implements ComponentType {
    name = 'Filter';

    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '.aui-container-filter';
        this.element = $(this.elementRef);
        this.hidingMorethenThreeListItemsOnLoad();
        Events.onClickMoreOrLess.call(this);
        Events.onKeyPress.call(this);
        Events.onMouseClick.call(this);
    }
    hidingMorethenThreeListItemsOnLoad() {
        $('ul.aui-list-container').each(function () {
            var $listContainer = $(this);
            var listLength = $($listContainer).find('li').length;
            if (listLength > 3) {
                $('li', $listContainer).eq(2).nextAll().hide().addClass('toggleable');
                $($listContainer).append('<li aria-label="view more button" class="more border-0 bg-white read-more-trigger w-100 mt-3" tabindex="0">View More Option</li>');
            }
        });
        $('.aui-filter-container').each(function () {
            var $filterList = $(this);
            $(".ui-filter-heading", $filterList).click(function (e) {
                e.preventDefault();
                $(".aui-filter-group", $filterList).toggle();
                return false;
            });
        });
    }

    triggerMouseClickEvent(e) {
        var parentElement = e.currentTarget.parentNode.parentNode;
        if (e.currentTarget.getAttribute('aria-checked') === 'true') {
            e.currentTarget.setAttribute('aria-checked', 'false');
            $(parentElement).removeClass('border-right-red');
        } else {
            e.currentTarget.setAttribute('aria-checked', 'true');
            $(parentElement).addClass('border-right-red');
        }
    }

    toggelMoreOrLessItems(e) {
        let list = this.element.find("ul.term-list");
        let buttonEle = e.target;
        if ($(buttonEle).hasClass('less')) {
            $(buttonEle).text('View More Option').removeClass('less');
            $(buttonEle).attr("aria-label", "collapse ");
        } else {
            $(buttonEle).text('View Less Option').addClass('less');
            $(buttonEle).attr("aria-label", "expanded ");
            let firstListItem = e.target.parentElement.firstElementChild.firstElementChild;
            $(firstListItem).focus();
        }
        $(buttonEle).siblings('li.toggleable').slideToggle();
    }

    triggerEnterKeyEvent(e) {
        var parentElement = e.currentTarget.parentNode.parentNode;
        if ((e.keyCode ? e.keyCode : e.which) == 13) {
            if (e.currentTarget.getAttribute('aria-checked') === 'true') {
                e.currentTarget.setAttribute('aria-checked', 'false');
                $(parentElement).removeClass('border-right-red');
            } else {
                e.currentTarget.setAttribute('aria-checked', 'true');
                $(parentElement).addClass('border-right-red');
            }
        }
    }
}