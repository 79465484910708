import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./mobile-nav.events";
export class MobileNav extends Component implements ComponentType {
    name = 'MobileNav';
    body = $('body');
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '#MobileNavModal';
        this.element = $(this.elementRef);
        Events.toggle.call(this);
    }
    addClass(className: string) {
        this.body.addClass(className);
    }
    removeClass(className: string) {
        this.body.removeClass(className);
    }
}