
const Global: any = global;
export function addEvents(componentObject: any) {

    if (componentObject.__proto__.hasOwnProperty('onDomReady')) {
        $(document).ready((e) => {
            componentObject.element = $(componentObject.elementRef)
            componentObject.onDomReady(e);
            // componentObject.prototype.init = componentObject.init;
            Global.AUI[componentObject.name] = componentObject;

        })
    }
    if (componentObject.__proto__.hasOwnProperty('onWindowResize')) {
        $(window).resize((e) => {
            componentObject.element = $(componentObject.elementRef)
            componentObject.onWindowResize(e);
        })
    }
}
