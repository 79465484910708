export default {
    onClick: function () {
        this.element.children('.dropdown-toggle').on('click', () => {
            this.setArrowPosition();
        })
    },
    shown: function () {
        this.element.on('shown.bs.dropdown', () => {
            setTimeout(this.setMenuArrowPositionToCenter, 1);
        });
    }
}