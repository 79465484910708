const Global: any = global;
Global.jQuery = Global.$ = require('jquery');
Global.AUI = {};
import 'bootstrap';
import 'popper.js';
import '../scss/aha.scss';
import './lib/_loader'
declare var APPINITILIZER;

class AppInitilizer {
    // init() {
    //     const mobileNav = new MobileNav();
    //     mobileNav.toggle();
    // }
}
// global['APPINITILIZER'] = new AppInitilizer();

// $(document).ready(($) => {
//     APPINITILIZER.init();
// });
