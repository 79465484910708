export default {
    onClickMoreOrLess: function () {
        this.element.find('ul.term-list').on('click', '.more', (e) => {
            this.toggelMoreOrLessItems(e);
        })
        this.element.find('ul.term-list').on('keypress', '.more', (e) => {
            this.toggelMoreOrLessItems(e);
        })
    },
    onCheckboxChecked: function () {
        this.element.find('ul.term-list').on('click', '.list-item', (e) => {
            this.activateFilter(e);
        })
    },
    onMouseClick: function () {
        this.element.find('ul.term-list').on('click', '.aui-checkbox-section', (e) => {
            this.triggerMouseClickEvent(e);
        })
    },
    onKeyPress: function () {
        this.element.find('ul.term-list').on('keypress', '.aui-checkbox-section', (e) => {
            this.triggerEnterKeyEvent(e);
        })
    },
}