import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./image-slider.events";
export class Imageslider extends Component implements ComponentType {
    name = 'Imageslider';
    onDomReady() {
        this.init();
    }
    onWindowResize() {
        this.resizeImageSlider();
    }
    init() {
        if ($('.aui-slider-parent li').length > 1) {
            this.elementRef = '.aui-image-slider';
            this.element = $(this.elementRef);
            this.resizeImageSlider();
            Events.onRightArrowclick.call(this);
            Events.onLeftArrowClick.call(this);
            Events.onThumbnailImageClick.call(this);
            Events.onEnterKeyPress.call(this);
        }
        else {
            $('.aui-left,.aui-right,.aui-thumbnail-parent').fadeOut(10);
        }
    }
    slideImageToRight() {
        
        $('.aui-slider-parent').animate({ 'left': parseInt($('.aui-slider-parent').css('left')) - parseInt($('.aui-slider-parent').find('li').css('width')) }, 300);
        var elem = document.querySelector('.aui-slider-parent li');
        var slidepositionone = parseInt($('.aui-slider-parent').css('left')) / parseInt($('.aui-slider-parent li').css('width'));
        var indexvalueone = Math.abs(Number(slidepositionone.toFixed(0))) + 2;
        if (indexvalueone == $('.aui-slider-parent li').length) {
            $('.aui-right').fadeOut(100);
            $('.aui-left').fadeIn(100);
        }
        else {
            $('.aui-left,.aui-right').fadeIn(100);
        }
        //$('.aui-activehigh-lights').animate({ 'left': $(".aui-thumbnail li:nth-child(" + indexvalueone + ")").css('left') });
        $('.aui-activehigh-lights').animate({ 'left': $(".aui-thumbnail li:nth-child(" + indexvalueone + ")").css('left') });
    }
    resizeImageSlider() {
        $('.aui-slider-parent').css({ 'width': $('.aui-slider-parent li').length * 100 + "%" });
        $('.aui-slider-parent').addClass('li-no' + $('.aui-slider-parent li').length);
        $('.aui-slider-parent').css({ 'left': '0px' });
        if ($('.aui-slider-parent li').length > 1) {
            $('.aui-left').fadeOut(0);
            $('.aui-right').fadeIn(0);
        }
        else {
            $('.aui-left,.aui-right,.aui-thumbnail-parent').fadeOut(0);
        }
        if ($(window).width() < 420) {
            $('.aui-slider-parent').css({ 'left': '0px' });
            $('.aui-activehigh-lights').css({ 'left': '0px', 'top': '0px' });
        }
        else {
            $('.aui-activehigh-lights').css({ 'left': '0px', 'top': '0px' });
        }
        if(document.documentElement.lang == 'ar'){
            var link = document.getElementsByClassName('.aui-right');
            for(let i=1; i<$('.aui-slider-parent li').length; i++){
                this.slideImageToRight();
            }
            setTimeout(function(){
                $('.aui-slider-parent').animate({ 'left': parseInt($('.aui-slider-parent').css('left')) - parseInt($('.aui-slider-parent').find('li').css('width')) }, 300);
                var elem = document.querySelector('.aui-slider-parent li');
                var slidepositionone = parseInt($('.aui-slider-parent').css('left')) / parseInt($('.aui-slider-parent li').css('width'));
                var indexvalueone = Math.abs(Number(slidepositionone.toFixed(0))) + 2;
                if (indexvalueone == $('.aui-slider-parent li').length) {
                    $('.aui-right').fadeOut(100);
                    $('.aui-left').fadeIn(100);
                }
                else {
                    $('.aui-left,.aui-right').fadeIn(100);
                }
                $('.aui-activehigh-lights').animate({ 'left': $(".aui-thumbnail li:nth-child(" + indexvalueone + ")").css('left') });
                },500);
        }
        
    }
    slideImageToLeft() {
        $('.aui-slider-parent').animate({ 'left': parseInt($('.aui-slider-parent').css('left')) + parseInt($('.aui-slider-parent li').css('width')) }, 300);
        var slidepositiontwo = parseInt($('.aui-slider-parent').css('left')) / parseInt($('.aui-slider-parent li').css('width'));
        var indexvalue = Math.abs(Number(slidepositiontwo.toFixed(0)));
        //$('.aui-activehigh-lights').animate({ 'left': $(".aui-thumbnail li:nth-child(" + indexvalue + ")").css('left') });
        $('.aui-activehigh-lights').animate({ 'left': $(".aui-thumbnail li:nth-child(" + indexvalue + ")").css('left') });
        
        if (indexvalue == 1) {
            $('.aui-left').fadeOut(100);
            $('.aui-right').fadeIn(100);
        }
        else {
            $('.aui-left,.aui-right').fadeIn(100);
        }
    }
    moveToImage(e) {
        if(document.documentElement.lang == 'ar'){
            if($(e.currentTarget).index() == 2){
                $('.aui-slider-parent').animate({ 'left': -2});
                $('.aui-activehigh-lights').animate({ 'left': 0 });
                $('.aui-left').fadeOut();
                $('.aui-right').fadeIn();
            }else if($(e.currentTarget).index() == 1){
                $('.aui-slider-parent').animate({ 'left': -($(e.currentTarget).index()) * $('.aui-slider-parent li').width() });
                $('.aui-activehigh-lights').animate({ 'left': $(e.currentTarget).css('left')});
                $('.aui-left,.aui-right').fadeIn();
            }else if($(e.currentTarget).index() == 0){
                $('.aui-slider-parent').animate({ 'left': -($('.aui-slider-parent li').width()) * 2});
                $('.aui-activehigh-lights').animate({ 'left': 198 });
                $('.aui-left').fadeIn();
                $('.aui-right').fadeOut();
            }

        }else{
            $('.aui-slider-parent').animate({ 'left': -($(e.currentTarget).index()) * $('.aui-slider-parent li').width() });
            $('.aui-activehigh-lights').animate({ 'left': $(e.currentTarget).css('left') });
        }
        if ($(e.currentTarget).index() == 0) {
            $('.aui-left').fadeOut();
            $('.aui-right').fadeIn();
        }
        else if ($(e.currentTarget).index() + 1 == $('.aui-thumbnail li').length) {
            $('.aui-left').fadeIn();
            $('.aui-right').fadeOut();
        }
        else {
            $('.aui-left,.aui-right').fadeIn();
        }

    }
}