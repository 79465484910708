export default {
   
    slide: function () {
        this.element.on('slide.bs.carousel', (e) => {
            e.stopPropagation();
            this.sliding(e);
        });
    },
    resize: function(){
        window.addEventListener('resize', (e) => {
        e.stopPropagation();
        this.windowResize();
        
        })
        }
}
