export const Debounce = (fn,delay)=>{
    let timoutid;
    return function(...args){	
        if(timoutid){
            clearTimeout(timoutid);
        }
        timoutid = setTimeout(function(){
            fn(...args)
        },delay);
    } 
}