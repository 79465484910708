import { Dropdown } from '../components/dropdown';
import { MobileNav } from '../components/mobile-nav';
import { Search } from '../components/search';
import { Minicart } from '../components/minicart';
import { Carousel } from '../components/carousel';
import { componetParser } from './_parser';
import { Filter } from '../components/filter';
import { Tooltip } from '../components/tooltip';
import { Imageslider } from '../components/image-slider';
import { Popover } from '../components/popover';
import { Tab } from '../components/tabs';
import { CarouselDesktop } from '../components/carousel-desktop';
import { Checkout } from '../components/checkout';
import { CountryNameAndCode } from '../components/countrynameandcode';
import { Bodyclass } from '../components/bodyclass';
import { DatePicker } from '../components/datepicker';
const Components = [
    Dropdown,
    MobileNav,
    Search,
    Minicart,
    Carousel,
    Filter,
    Tooltip,
    Imageslider,
    Popover,
    Tab,
    CarouselDesktop,
    Checkout,
    CountryNameAndCode,
    Bodyclass,
    DatePicker
]
componetParser(Components);