import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./tooltip.events";
export class Tooltip extends Component implements ComponentType {
    name: string = 'Tooltip';
    onDomReady() {
        Events.init.call(this);
    }
    init() {
        this.elementRef = '[data-toggle="tooltip"]';
        this.element = $(this.elementRef);
        this.element.tooltip();
    }
}
