import { Debounce } from "../util";
export default {
    onRightArrowclick: function () {
        this.element.find('.aui-right').on('click', Debounce(e => {
            this.slideImageToRight();
        }, 250));
    },
    onLeftArrowClick: function () {
        this.element.find('.aui-left').on('click', Debounce(e => {
            this.slideImageToLeft();
        }, 250));
    },
    onThumbnailImageClick: function () {
        this.element.find('.aui-thumbnail li').on('click', (e) => {
            this.moveToImage(e);
        });
    },
    onEnterKeyPress: function () {
        this.element.find('ul.aui-thumbnail').on('keypress', 'li', (e) => {
            if (e.which == 13) {
                this.moveToImage(e);
            }
        })
    }
}