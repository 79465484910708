export default {
    keyup: function () {
        this.element.find("input").keyup((e) => {
            this.findingSearchword(e.target.value);
            if(e.which == 40){
                this.setfocustoULcontainer(e);
            }
        });
        $(document).on('keyup', (e) => {
            if (e.which == 9) {
                this.onHidesearchdropDown();
            }
        });
        this.element.on("keyup", (e) => {
            if (e.which == 9) {
                e.stopPropagation();
            }
            if (e.which == 13) {
                this.showULcontainer();
            }
        }); 
    },
    blur: function () {
        $(document).click((e) => {
            this.onHidesearchdropDown();
        });
    },
    click: function () {
        this.element.find(".aui-input-div").click((e) => {
            this.showULcontainer();
            e.stopPropagation();
        });
    },
    select: function () {
        this.element.find("li").click((e) => {
            this.onselectOption(e);
        });
    },
}