import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./dropdown.events";
export class Dropdown extends Component implements ComponentType {
    name = 'Dropdown';
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '.c-nav-dropdown__container';
        this.element = $(this.elementRef);
        Events.onClick.call(this);
    }
    setArrowPosition() {
        Events.shown.call(this);
    }
    setMenuArrowPositionToCenter() {
        let dropdown = $('.c-nav-dropdown__container.show');
        let menu = $(dropdown).children('.dropdown-menu');
        let toggle = dropdown.children('button')[0];
        let halfArrowWidth = Math.floor($(menu).children('.c-nav-dropdown__arrow')[0].getBoundingClientRect().width / 2);
        let halfToggleWidth = Math.floor(toggle.getBoundingClientRect().width / 2);
        let toggleLeftEdge = toggle.getBoundingClientRect().left;
        let menuLeftEdge = menu[0].getBoundingClientRect().left;
        $(menu).children('.c-nav-dropdown__arrow')[0].style.left =
            (toggleLeftEdge + halfToggleWidth) - (menuLeftEdge + halfArrowWidth) + 'px';
    }
}
