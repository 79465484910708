import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./minicart.events";
export class Minicart extends Component implements ComponentType {
    name = 'Minicart';
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '.aui-minicart';
        this.element = $(this.elementRef);
        Events.onClick.call(this);
    }
}
