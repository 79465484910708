import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
const intlTelInput = require ('intl-tel-input');
export class CountryNameAndCode extends Component implements ComponentType {
    name = 'CountryNameAndCode';
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '#phone';
        this.element = document.querySelector(this.elementRef);
        if(this.element){
            intlTelInput(this.element, {
                 preferredCountries:["US", "IN", "gb"],
                 separateDialCode:true
            }); 
        }    
    } 
}