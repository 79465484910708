export default {
    onCardSlide: function () {
        this.element.on('slide.bs.carousel', (e) => {
            e.stopPropagation();
            this.onSliding(e);
        });
    },
    onKeyPress: function (){
        this.element.on('keydown.bs.carousel',(e)=>{
            this.triggerKeyPress(e);
        });
    }
}