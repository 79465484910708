import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
export class Checkout extends Component implements ComponentType {
    name = 'checkout';
    
    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '.navbar-expand-lg';
        this.element = $(this.elementRef);
        this.initiateAccordion();
    }
   initiateAccordion() {
        Array.prototype.slice.call(document.querySelectorAll('.aui-nav-accordion')).forEach(function (accordion) {

        // Allow for multiple accordion sections to be expanded at the same time
        var allowMultiple = accordion.hasAttribute('data-allow-multiple');
        // Allow for each toggle to both open and close individually
        var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute('data-allow-toggle');
        // Create the array of toggle elements for the accordion group
        var triggers = Array.prototype.slice.call(accordion.querySelectorAll('.Accordion-trigger'));
        // Bind keyboard behaviors on the main accordion container
        accordion.addEventListener('keydown', function (event) {
            var target = event.target;
            var key = event.which.toString();
            var isExpanded = target.getAttribute('aria-expanded') == 'true';
            var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute('data-allow-toggle');

            // 33 = Page Up, 34 = Page Down
            var ctrlModifier = (event.ctrlKey && key.match(/33|34/));

            // Is this coming from an accordion header?
            if (target.classList.contains('Accordion-trigger')) {
                // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
                // 38 = Up, 40 = Down
                if (key.match(/38|40/) || ctrlModifier) {
                    var index = triggers.indexOf(target);
                    var direction = (key.match(/34|40/)) ? 1 : -1;
                    var length = triggers.length;
                    var newIndex = (index + length + direction) % length;

                    triggers[newIndex].focus();

                    event.preventDefault();
                }
                else if (key.match(/35|36/)) {
                    // 35 = End, 36 = Home keyboard operations
                    switch (key) {
                        // Go to first accordion
                        case '36':
                            triggers[0].focus();
                            break;
                        // Go to last accordion
                        case '35':
                            triggers[triggers.length - 1].focus();
                            break;
                    }
                    event.preventDefault();
                }
            }
        });
    });
   }
}